<template>
  <!--begin::Post-->
  <div class="post d-flex flex-column-fluid" id="kt_post">
    <!--begin::Container-->
    <div id="kt_content_container" class="container-xxl">
      <!--begin::Card-->
      <div class="card">
        <!--begin::Card body-->
        <div class="card-body">
          <div v-if="message" class="alert alert-danger mb-10" role="alert">
            {{ message }}
          </div>
          <Form
            @submit="handleSubmit"
            :validation-schema="schema"
            class="form w-100"
            novalidate="novalidate"
            id="kt_sign_in_form"
          >
            <!--             
            <FormGroup :title="'Menu'" :forms="formFields"></FormGroup>
            <button
              class="btn btn-secondary"
              @click="$router.push({ name: 'menu-list' })"
            >
              Back
            </button>
            <button
              :disabled="loading"
              type="submit"
              id="kt_sign_in_submit"
              class="btn btn-success"
            >
              <span class="indicator-label">Submit</span>
              <span class="indicator-progress" v-show="loading">
                Please wait...
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                >
                </span>
              </span>
            </button> -->

            <div class="mb-5" v-for="(input, key) in formInput" :key="key">
              <label for="" class="form-label"> {{ input.label }} </label>
              <span v-if="input.type == 'select'">
                <Field
                  as="select"
                  :placeholder="input.placeholder"
                  :name="input.name"
                  class="form-control bg-transparent"
                  v-model="formInput[key].value"
                >
                  <option value="">Select value</option>
                  <option
                    v-for="(select, i) in input.options"
                    :key="i"
                    :value="select.value"
                  >
                    {{ select.label }}
                  </option>
                </Field>
              </span>
              <span v-else>
                <Field
                  type="text"
                  :placeholder="input.placeholder"
                  :name="input.name"
                  class="form-control bg-transparent"
                  v-model="formInput[key].value"
                />
              </span>
              <ErrorMessage
                :name="input.name"
                class="text-danger error-feedback"
              />
            </div>

            <div>
              <button
                class="btn btn-secondary"
                @click="$router.push({ name: 'menu-list' })"
              >
                Back
              </button>
              <button
                :disabled="loading"
                type="submit"
                id="kt_sign_in_submit"
                class="btn btn-success"
              >
                <span class="indicator-label">Submit</span>
                <span class="indicator-progress" v-show="loading">
                  Please wait...
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  >
                  </span>
                </span>
              </button>
            </div>
          </Form>
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Card-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Post-->
</template>

<script>
// import { toRaw } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
// import FormGroup from "@/components/FormGroup.vue";

import * as yup from "yup";
import Service from "../../../services/base.service";

export default {
  components: {
    Form,
    Field,
    ErrorMessage,
    // FormGroup,
  },
  data() {
    const schema = yup.object().shape({
      label: yup.string().required("Label is required!"),
      url: yup.string().required("URL is required!"),
    });

    return {
      loading: false,
      message: "",
      formInput: [
        {
          label: "Label",
          value: "",
          type: "text",
          name: "label",
          placeholder: "Label",
        },
        {
          label: "URL",
          value: "",
          type: "text",
          name: "url",
          placeholder: "/kta-list",
        },
        {
          label: "Ordering",
          value: "",
          type: "text",
          name: "ordering",
          placeholder: "",
        },
        {
          label: "Parent",
          value: "",
          options: [],
          type: "select",
          name: "parent_id",
          placeholder: "",
        },
      ],
      schema,
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.fetchData();
    }
    this.fetchParentOptions();
    // this.getListData();
  },
  methods: {
    async fetchParentOptions() {
      this.loading = true;
      try {
        const BaseService = new Service("menu");
        const { data } = await BaseService.getListData({ size: 99999 });
        this.formInput[3].options = data.map((r) => {
          return {
            label: `#${r.ordering} - ${r.label} `,
            value: r.id,
          };
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async fetchData() {
      this.loading = true;
      try {
        const BaseService = new Service("menu");
        const { data } = await BaseService.getData(this.$route.params.id);
        this.formInput.forEach((e, i) => {
          this.formInput[i].value = data[this.formInput[i].name];
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async handleSubmit(data) {
      this.loading = true;

      const BaseService = new Service("menu");
      try {
        const { message } = this.$route.params.id
          ? await BaseService.updateData(this.$route.params.id, data)
          : await BaseService.createData(data);
        this.message = message;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
